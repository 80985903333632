import {ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideHttpClient, withInterceptors} from "@angular/common/http";

import {routes} from './app.routes';
import {API_URL, BUCKET_URL} from "./shared/custom-tokens/custom-tokens";
import {environment} from "../environments/environment";
import {tokenInterceptor} from "./core/interceptors/token/token.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideHttpClient(
      withInterceptors(
        [tokenInterceptor]
      ),),
    provideAnimationsAsync(),
    {
      provide: API_URL,
      useValue: environment.apiUrl
    },
    {
      provide: BUCKET_URL,
      useValue: environment.bucketUrl
    },
  ]
};
