import {HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject} from "@angular/core";

import {MockToken} from "./__mock__/mock-token";
import {StorageService} from "../../../shared/services/storage/storage.service";
import {AnyType} from "../../../shared/types/global.type";

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const storageService = inject(StorageService);
  const token = storageService.getToken();
  const headers = req.headers;
  const bucketValue = headers.get("bucket");
  let clonedReq: HttpRequest<AnyType>;
  if (!bucketValue) {
    clonedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${MockToken}`
      }
    })
  }
  const request = (token && !bucketValue) ? clonedReq! : req;
  return next(request);
};
