import { Routes } from '@angular/router';
import {authGuard} from "./core/guards/auth/auth.guard";

export const routes: Routes = [
  {
    path: "admin",
    canActivate: [authGuard],
    loadChildren: () => import("./features/admin/admin.routes").then( a => a.ADMIN_ROUTE )
  },
  {
    path: "auth",
    loadChildren: () => import("./features/auth/auth.routes").then( a => a.AUTH_ROUTE )
  },
  {
    path: "",
    redirectTo: "admin",
    pathMatch: 'full'
  }
];
