import {Component, LOCALE_ID} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import localeEs from '@angular/common/locales/es';
import {registerLocaleData} from "@angular/common";
import {provideNativeDateAdapter} from "@angular/material/core";

registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    provideNativeDateAdapter(),
    { provide: LOCALE_ID, useValue: 'es' }
  ],
})
export class AppComponent {
  title = 'aib-ads-front';
}
